import { getOrCreateProcedure, type IProcedureOptions, type Procedure } from 'o365-modules'
import { ref } from 'vue';
import { logger } from 'o365-utils'

export const procedureStores = new Map<string, Procedure>();

export function getOrCreateReactiveProcedure<T extends object = any>(pOptions: IProcedureOptions): Procedure<T> {
    logger.warn('getOrCreateReactiveProcedure has been deprecated since getOrCreateProcedure now returns a proxy')
    return getOrCreateProcedure(pOptions);
    if (!procedureStores.has(pOptions.id)) {
        procedureStores.set(pOptions.id, ref(getOrCreateProcedure(pOptions)).value);
    }
    return procedureStores.get(pOptions.id)!;
}
